import type { ComponentType } from "react"

export function withClass<
    P extends { className?: string; style?: React.CSSProperties },
>(Component: ComponentType<P>): ComponentType<P> {
    return (props: P) => {
        const updatedProps = { ...props }

        // Adiciona a classe 'esconder'
        updatedProps.className = `${props.className || ""} esconder`.trim()

        // Adiciona ou atualiza a propriedade style para incluir display: none
        updatedProps.style = {
            ...props.style,
            display: "none",
        }

        return <Component {...updatedProps} />
    }
}
